import React, {Component} from 'react';
import firebase from '../Firebase';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import {Dialog, DialogActions, DialogContent, DialogTitle, TableSortLabel} from "@material-ui/core";
import {calculateRank} from "../helpers";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  adminTable: {
    tableLayout: 'fixed'
  },
  levelUpButton: {
    color: theme.palette.link.color,
    display: 'contents',
  }
});

class Admin extends Component {

  constructor(props) {
    super(props);
    this.refTeams = firebase.firestore().collection("teams").orderBy("sumOfPoints", "desc");
    this.teamsUnsubscribe = null;
    this.downloadTeamUploads = this.downloadTeamUploads.bind(this);
    this.downloadSubjectUploads = this.downloadSubjectUploads.bind(this);
    this.resetPasswords = this.resetPasswords.bind(this);
    this.resetPasswords = this.resetPasswords.bind(this);
    this.updateUserState = this.updateUserState.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    this.state = {
      selectedTeamId: 0,
      teams: [],
      authTeams: null,
      alert: 0,
      showChangeEmailDialog: false,
      showLevelUpDialog: false,
      selectedTeam: null,
      newEmail: '',
    }

  }

  handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ alert: 0 });
  };

  onTeamsCollectionUpdate = (querySnapshot) => {
    let teamsWithRank = calculateRank(querySnapshot);
    const teams = [];
    teamsWithRank.forEach((team) => {
      const {name, number, school, email, sumOfPoints, level, openKeyQuestion, uploadRoundSubmit, rank} = team;
      teams.push({
        id: team.id,
        rank: rank,
        number: Number(number),
        name: name,
        school: school,
        sumOfPoints: parseFloat(Number(sumOfPoints).toFixed(2)),
        level: level,
        openKeyQuestion: openKeyQuestion,
        email: email,
        uploadRoundSubmit: uploadRoundSubmit,
        state: null,
      });
    });
    this.setState({
      teams: teams.sort((a,b) => (a.number > b.number) ? 1 : -1),
      orderBy: 'rank',
      order: 'asc',
    });
  }


  componentDidMount() {
    this.teamsUnsubscribe = this.refTeams.onSnapshot(this.onTeamsCollectionUpdate);
    const teams = firebase.functions().httpsCallable('getUsers');
    teams().then((resp) => {
      if(resp.data !== undefined && resp.data !== null && Array.isArray(resp.data)) {
        const map2 = new Map(resp.data.map(obj => [obj.uid, obj]));
        console.log(map2);
        this.setState({authTeams: map2});
      }
    })

  }

  downloadSubjectUploads(subject) {
    console.log("downloadSubjectUploads", subject)
    const createZip = firebase.functions().httpsCallable('createSubjectZip');
    createZip({
      subject: subject,
      zipFilePath: `firstRound/subjects/${subject}.zip`,
    })
        .then(async (result) => {
          console.log(result)
          const storageRef = firebase.storage().ref(`firstRound/subjects/`);
          const fileRef = storageRef.child(`${subject}.zip`)
          fileRef.getDownloadURL().then((url) => {
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })

        })
        .catch((error) => {
          this.setState({alert: 2});
          console.log(error.code);
          console.log(error.message);
          console.log(error.details);
        })

  }


  downloadTeamUploads(teamId) {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    console.log("downloadTeamUploads", teamId)
    const createZip = firebase.functions().httpsCallable('createTeamZip');
    createZip({
      teamId: teamId,
      zipFilePath: `firstRound/${teamId}/${teamId}.zip`,
      folderPath: `firstRound/${teamId}`,
    })
        .then(async (result) => {
          console.log(result)
          await delay(1000);
          const storageRef = firebase.storage().ref(`firstRound/${teamId}/`);
          const fileRef = storageRef.child(`${teamId}.zip`)
          fileRef.getDownloadURL().then((url) => {
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })

        })
        .catch((error) => {
          this.setState({alert: 2});
          console.log(error.code);
          console.log(error.message);
          console.log(error.details);
        })
  }


  resetPasswords(email) {
    if (email !== undefined) {
      console.log("resetPasswords", email);
      firebase.auth().sendPasswordResetEmail(email).then(value => {
        console.log(value);
        this.setState({alert: 1});
      }).catch(reason => {
        console.log(reason);
        this.setState({alert: 2})
      })
    }
  }

  updateUserState(uid, disabled) {
    console.log("updateUserState", uid, disabled);
    var updateUserState = firebase.functions().httpsCallable('updateUserState');
    updateUserState({
      teamUid: uid,
      disabled: disabled,
    })
        .then((result) => {
          console.log("update state success");
          this.setState({alert: 1});
        })
        .catch((error) => {
          console.log(error.code);
          console.log(error.message);
          this.setState({alert: 2});
        })
  }
  changeEmail() {
    console.log("new email",this.state.newEmail);

    var addMessage = firebase.functions().httpsCallable('changeTeamEmail');
    addMessage({
      newEmail: this.state.newEmail,
      email: this.state.selectedTeam.email,
      teamId: this.state.selectedTeam.id,
    })
        .then((result) => {
          console.log("changed email success");
          this.setState({alert: 1});
        })
        .catch((error) => {
          console.log(error.code);
          console.log(error.message);
          this.setState({alert: 2});
        })

    this.setState({newEmail: '', selectedTeam: null});
  }

  levelUp() {
    console.log("level up",this.state.selectedTeam);

    const levelUpTeam = firebase.functions().httpsCallable('levelUpTeam');
    levelUpTeam({
      teamId: this.state.selectedTeam.id,
    })
        .then((result) => {
          console.log("level up sucess");
          this.setState({alert: 1});
        })
        .catch((error) => {
          console.log(error.code);
          console.log(error.message);
          this.setState({alert: 2});
        })

    this.setState({selectedTeam: null});
  }


  render() {
    const { classes } = this.props;

    const headCells = [
      {
        id: 'rank',
        numeric: false,
        disablePadding: false,
        label: 'Pořadí',
        align: 'center',
        maxWidth: "500px",
      },
      {
        id: 'number',
        numeric: true,
        disablePadding: true,
        label: 'ID',
        align: 'center',
        maxWidth: "20px",
      },
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Jméno',
        align: 'left',
        maxWidth: "20px",
      },
      {
        id: 'school',
        numeric: false,
        disablePadding: false,
        label: 'Škola',
        align: 'left',
        maxWidth: "20px",
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
        align: 'left',
        maxWidth: "20px",
      },
      {
        id: 'sumOfPoints',
        numeric: true,
        disablePadding: true,
        label: 'Počet bodů',
        align: 'center',
        maxWidth: "10px",
      },
      {
        id: 'level',
        numeric: true,
        disablePadding: true,
        label: 'Úroveň',
        align: 'center',
        maxWidth: "10px",
      }
    ];

    const createSortHandler = (property) => (event) => {
      const isAsc = this.state.orderBy === property && this.state.order === 'asc';
      this.setState({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property
      });
    };

    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

    function getComparator(order, orderBy) {
      return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }

    return (
        <Container>
          <Box my={2}>
            <CssBaseline />
            <Typography
                gutterBottom
                variant="h4"
                component="h2">
              Administrace
            </Typography>

            <Typography
                gutterBottom
                variant="h5"
                component="h2">
              Hromadné akce
            </Typography>

            <Box
                my={2}
                padding={2}
                component={Paper}>
              <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                <Grid align="left" item xs={12}>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="h3">
                  Všechny týmy
                </Typography>
                </Grid>
                <Grid align="center" item xs={6}>
                  <Button variant="contained" onClick={() => this.updateUserState(null, false)}>Aktivovat</Button>
                </Grid>
                <Grid align="center" item xs={6}>
                  <Button variant="contained" onClick={() => this.updateUserState(null, true)}>Deativovat</Button>
                </Grid>
              </Grid>
            </Box>
          <Typography gutterBottom variant="h5" component="h2">
            Přehled týmu
          </Typography>

          <TableContainer component={Paper}>
            <Table aria-label="simple table" className={classes.adminTable}>
              <colgroup>
                <col style={{width:'7%'}}/>
                <col style={{width:'5%'}}/>
                <col style={{width:'18%'}}/>
                <col style={{width:'15%'}}/>
                <col style={{width:'20%'}}/>
                <col style={{width:'5%'}}/>
                <col style={{width:'5%'}}/>
                <col style={{width:'4%'}}/>
                <col style={{width:'8%'}}/>
                <col style={{width:'9%'}}/>
                <col style={{width:'12%'}}/>
              </colgroup>

              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                      <TableCell align={headCell.align}
                                 key={headCell.id}
                                 padding={headCell.disablePadding ? 'none' : 'normal'}
                                 sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                      >
                        <TableSortLabel
                            active={this.state.orderBy === headCell.id}
                            direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {this.state.orderBy === headCell.id ? (
                              <Box component="span" style={{display: "none"}} >
                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </Box>
                          ) : null}
                        </TableSortLabel></TableCell>
                  ))}
                  <TableCell padding={"none"} align="center">Kličová otázka</TableCell>
                  <TableCell align="center"  padding={"none"}>Reset hesla</TableCell>
                  <TableCell align="center">Změna emailu</TableCell>
                  <TableCell align="center">Stav</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.teams.slice().sort(getComparator(this.state.order, this.state.orderBy)).map((team) => (
                  <TableRow key={team.id}>
                    <TableCell component="th" scope="row">{team.rank}</TableCell>
                    <TableCell component="th" scope="row">
                      <Link  to={`/team/${team.id}`} > {team.number}</Link>
                    </TableCell>
                    <TableCell align="left">{team.name}</TableCell>
                    <TableCell align="left">{team.school}</TableCell>
                    <TableCell style={{ wordWrap: 'break-word'}} align="left">{team.email}</TableCell>
                    <TableCell align="center">{isNaN(team.sumOfPoints) ? team.sumOfPoints : team.sumOfPoints.toFixed(2)}</TableCell>
                    <TableCell align="center">
                      <Button
                          variant="text"
                          className={classes.levelUpButton}
                          onClick={() => this.setState({showLevelUpDialog: true, selectedTeam: team})}>{team.level}</Button>
                    </TableCell>
                    <TableCell align="center">
                      {team.openKeyQuestion === true ? <LockOpenIcon /> : <LockIcon />
                      }
                    </TableCell>
                    <TableCell>
                      <Button
                          variant="contained"
                          onClick={() => this.resetPasswords(team.email)}>RST</Button>
                    </TableCell>
                    <TableCell>
                      <Button
                          variant="contained"
                          onClick={() => this.setState({showChangeEmailDialog: true, selectedTeam: team})}>Změna</Button>
                    </TableCell>
                    <TableCell>
                      {this.state.authTeams != null && (
                        <Button
                            variant="contained"
                            onClick={() => this.updateUserState(team.id, !this.state.authTeams.get(team.id).disabled)}>
                          {this.state.authTeams.get(team.id).disabled ? "Aktivovat": "Deaktivovat"}
                        </Button>
                      )
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>

          <Snackbar open={this.state.alert === 1} autoHideDuration={5000} onClose={this.handleCloseAlert}>
            <Alert severity="success">Úspěch - podařilo se</Alert>
          </Snackbar>
          <Snackbar open={this.state.alert === 2} autoHideDuration={5000} onClose={this.handleCloseAlert}>
            <Alert severity="error">Aj chyba - něco se podařilo!</Alert>
          </Snackbar>

          <Dialog
              open={this.state.showChangeEmailDialog}
              onClose={() => this.setState({showChangeEmailDialog: false})}
              scroll="paper"
              maxWidth="lg"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Změna emailu</DialogTitle>
            <DialogContent dividers={true}>
              <div className="form-group">
                <label><strong>Tým:</strong></label>
                <div>{this.state.selectedTeam?.name}</div>
                <label><strong>Stavající email:</strong></label>
                <div>{this.state.selectedTeam?.email}</div>
                <label htmlFor="newEmail"><strong>Nový email:</strong></label>
                <input
                    type="text"
                    className="form-control"
                    name="newEmail"
                    value={this.state.newEmail}
                    onChange={(e) => this.setState({newEmail: e.target.value})}
                    />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showChangeEmailDialog: false})}>Zavřít</Button>
              <Button color="primary" onClick={() => {this.changeEmail(); this.setState({showChangeEmailDialog: false})}}>Změnit</Button>
            </DialogActions>
          </Dialog>

          <Dialog
              open={this.state.showLevelUpDialog}
              onClose={() => this.setState({showLevelUpDialog: false})}
              scroll="paper"
              maxWidth="lg"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Povýšení úrovně</DialogTitle>
            <DialogContent dividers={true}>
              <div className="form-group">
                <label><strong>Tým:</strong></label>
                <div>{this.state.selectedTeam?.name}</div>
                <label><strong>Aktuální uroveň:</strong></label>
                <div>{this.state.selectedTeam?.level}</div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showLevelUpDialog: false})}>Zavřít</Button>
              <Button color="primary" onClick={() => {this.levelUp(); this.setState({showLevelUpDialog: false})}}>Povýšit</Button>
            </DialogActions>
          </Dialog>

        </Container>
    );
  }
}

export default withStyles(styles)(Admin);