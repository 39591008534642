// Importing dependencies
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// NEXT STEP WILL HAVE THE TRANSLATIONS
// importing our translations from locales folder
import translations from './locales'

// i18n config
const i18nConfig = {
    resources: translations,
    fallbackLng: 'cs-CZ',
    defaultNS: 'translations',
    supportedLngs: ['cs-CZ', 'sk-SK', 'en-GB']
}

// I'm not going to talk about namespaces
// Simplicity is my goal

i18n
    .use(LanguageDetector) // Uses browser language detection
    .use(initReactI18next) // Uses i18n react package
    .init(i18nConfig) // Uses our configs

export default i18n