import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/styles';
import './App.css';
import firebase from './Firebase';
import QuestionCard from './components/QuestionCard';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Alert, AlertTitle } from '@material-ui/lab';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18next, { t, i18n } from 'i18next';



const styles = theme => ({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  questionLevelDivider: {
    margin: 50
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    var teamId = this.props.match.params.teamId;
    this.refQuestions = firebase.firestore().collection('teams/' + teamId + '/questions').orderBy('order');
    this.refTeam = firebase.firestore().collection('teams').doc(teamId);
    this.refMessage = firebase.firestore().collection('config/').doc("appMessage");
    this.questionsUnsubscribe = null;
    this.teamUnsubscribe = null;
    this.messageUnsubscribe = null;
    this.state = {
      startTime: null,
      endTime: null,
      startUploadTime: null,
      endUploadTime: null,
      questions: new Array(),
      teamId: teamId,
      team: '',
    };
  }

  onQuestionsCollectionUpdate = (querySnapshot) => {
    const questions = new Array();
    for (var i = 0; i < 5; i++) {
      questions[i] = new Array();
      for (var j = 0; j < 5; j++) {
        questions[i][j] = new Array();
      }
    }
    querySnapshot.forEach((doc) => {
      const { name, subject, answered, level, point, lastAttempt } = doc.data();
      let localizedName = name[i18next.language];

      if (subject != undefined) {
        switch (subject) {
          case 'ch': questions[level - 1][0].push({ key: doc.id, doc, name: localizedName, subject, answered, level, point, lastAttempt }); break;
          case 'bi': questions[level - 1][1].push({ key: doc.id, doc, name: localizedName, subject, answered, level, point, lastAttempt }); break;
          case 'fy': questions[level - 1][2].push({ key: doc.id, doc, name: localizedName, subject, answered, level, point, lastAttempt }); break;
          case 'ma': questions[level - 1][3].push({ key: doc.id, doc, name: localizedName, subject, answered, level, point, lastAttempt }); break;
        }
      } else {
        questions[level - 1][4].push({ key: doc.id, doc, name: localizedName, subject, answered, level, point });
      }

    });
    this.setState({
      questions: questions,
    });
  }

  onTeamCollectionUpdate = (documentSnapshpot) => {
    this.setState({
      team: documentSnapshpot.data(),
    })
  }

  onMessageCollectionUpdate = (documentSnapshpot) => {
    if (documentSnapshpot.exists) {
      this.setState({message: documentSnapshpot.data()});
    }
  }

  componentDidMount() {
    this.teamUnsubscribe = this.refTeam.onSnapshot(this.onTeamCollectionUpdate);
    this.questionsUnsubscribe = this.refQuestions.onSnapshot(this.onQuestionsCollectionUpdate);
    this.messageUnsubscribe = this.refMessage.onSnapshot(this.onMessageCollectionUpdate);

    firebase.firestore().collection("config").doc("openTime").get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            startTime: new Date(doc.data().start.seconds * 1000 + doc.data().start.nanoseconds / 1000000),
            endTime: new Date(doc.data().end.seconds * 1000 + doc.data().end.nanoseconds / 1000000),
          });

          if (new Date() < this.state.startTime) {
            setTimeout(() => window.location.reload(), this.state.startTime - new Date());
          } else if (new Date() > this.state.startTime && new Date() < this.state.endTime) {
            setTimeout(() => window.location.reload(), this.state.endTime - new Date());
          }
        } else {
          console.log('Chybí konfigurace!');
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Container>
        <Box my={2}>
          <CssBaseline />
          <div className={classes.paper}>
            {this.state.message?.visible &&
                <Alert severity={this.state.message.severity}>
                  {this.state.message.heading &&
                      <AlertTitle>{this.state.message.heading}</AlertTitle>
                  }
                  {this.state.message.text}
                </Alert>
            }

          </div>
          {this.state.questions[0] !== undefined && this.state.questions[0][0][0] !==  undefined &&
            [0, 1, 2, 3].map((level) => (
              <Box my={2}>
                <Grid container spacing={3} justify="flex-end">
                  {[0, 1, 2, 3].map((subject) => (
                    [0, 1, 2, 3].map((count) => (
                      <Grid item xs={6} sm={3}>
                        <QuestionCard
                          name={this.state.questions[level][count][subject].name}
                          id={this.state.questions[level][count][subject].key}
                          teamId={this.state.teamId}
                          answered={this.state.questions[level][count][subject].answered}
                          opened={this.state.questions[level][count][subject].level <= this.state.team.level}
                          points={this.state.questions[level][count][subject].point}
                          lastAttempt={this.state.questions[level][count][subject].lastAttempt}
                        />
                      </Grid>
                    ))
                  ))}
                  {this.state.questions[level][4][0] != undefined &&
                    <Grid item xs={3}>
                      <QuestionCard
                        name={this.state.questions[level][4][0].name}
                        id={this.state.questions[level][4][0].key}
                        teamId={this.state.teamId}
                        answered={this.state.questions[level][4][0].answered}
                        opened={this.state.questions[level][4][0].level <= this.state.team.level && (this.state.team.openKeyQuestion == true || this.state.questions[level][4][0].answered == true)}
                        points={this.state.questions[level][4][0].point} />
                    </Grid>
                  }
                </Grid>
                <Divider className={classes.questionLevelDivider} />
              </Box>
            ))
          }

          {this.state.questions[0] == undefined && new Date() > this.state.startTime && new Date() < this.state.endTime &&
            <Container component="main" maxWidth="xs">
              <Box
                display="flex"
                align="center"
                width={350} height={300}
              >
                <Box m="auto">
                  <CircularProgress />
                </Box>
              </Box>
            </Container>
          }

          <Box  my={2} >
            {this.state.startTime != null && new Date() < this.state.startTime &&
              <Alert icon={<AccessTimeIcon fontSize="inherit" />} severity="warning">
                <AlertTitle>{t("app.startTime.title")}</AlertTitle>
                <span dangerouslySetInnerHTML={{__html: t("app.startTime.message",{ 'interpolation': {'escapeValue': false}, startDate: this.state.startTime.toLocaleDateString(), startTime: this.state.startTime.toLocaleTimeString()}) }} />
              </Alert>
            }
          </Box>

          {this.state.endTime != null && new Date() > this.state.endTime &&
          <Alert icon={<AccessTimeIcon fontSize="inherit" />} severity="warning">
            <AlertTitle>{t("app.endTime.title")}</AlertTitle>
            <span dangerouslySetInnerHTML={{__html: t("app.endTime.message",{ 'interpolation': {'escapeValue': false}, startDate: this.state.endTime.toLocaleDateString(), startTime: this.state.endTime.toLocaleTimeString()}) }} />
          </Alert>
          }
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles)(App);
