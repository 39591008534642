import ReactHtmlParser from "react-html-parser";
import Box from "@material-ui/core/Box";
import React, {useEffect} from "react";
import {Checkbox, List, ListItem, ListItemButton, ListItemIcon} from "@mui/material";

function QuestionMultichoiceType(props) {
    const [checkedMap, setCheckedMap] = React.useState([]);

    useEffect(() => {
        if(props.answer.length === 0) {
            setCheckedMap(props.choices.map((element, index) => ({ index, element, checked: false })));
        }
    }, [props.answer]);

    const handleToggle = (choice, index) => () => {
        const find = checkedMap.find((ans) => ans.index === index);
        find.checked = find.checked !== true;
        const newChecked = checkedMap.map((c, i) => {
            if (i === index) {
                return find;
            } else {
                return c;
            }
        });

        setCheckedMap(newChecked);
        props.onChange(newChecked.filter((ans) => ans.checked).map((ans) => ans.element));
    };

    return (
        <>
        <Box
            id="mathdiv"
            my={2}>
            {ReactHtmlParser(props.text)}
        </Box>
            <List>
            {props.choices.map((choice, index) => {
                const labelId = `checkbox-list-label-${choice}`;

                return (
                    <ListItem
                        key={choice}
                    >
                        <ListItemButton
                            role={undefined}
                            onClick={handleToggle(choice, index)}
                            disabled={props.answered}
                            dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checkedMap[index] !== undefined ? checkedMap[index].checked : false}
                                    tabIndex={-1}
                                    disabled={props.answered}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            {ReactHtmlParser(choice)}
                        </ListItemButton>
                    </ListItem>
                );
            })}
            </List>
        </>
    );
}

export default QuestionMultichoiceType;