import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Admin from './components/Admin';
import Show from './components/Show';
import {AuthProvider} from './Auth';
import Login from "./components/Login";
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import Header from './Header';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import PasswordReset from "./components/PasswordReset";
import ScrollRestoration from 'react-scroll-restoration'
import './i18n'



const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00a9ce',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00c42c',
      contrastText: '#ffffff'
    },
    link: {
      color: '#007bff',
    }
  },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <ScrollRestoration />
          <Header />
          <div style={{minHeight: "100vh"}}>
            <Switch>
            <PrivateRoute
                exact
                path="/team/:teamId"
                component={App} />
            <Route
                exact
                path="/"
                component={Login} />
            <Route
                exact
                path="/login"
                component={Login} />
            <Route
                exact
                path="/password"
                component={PasswordReset} />
            <AdminRoute
                exact
                path="/admin"
                component={Admin} />
            <PrivateRoute
                exact
                path="/team/:teamId/question/:id"
                component={Show} />
            </Switch>
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
