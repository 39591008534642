import React, { useContext } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import firebase from './Firebase';
import TeamBar from './TeamBar';
import { AuthContext } from "./Auth";
import logoCs from './assets/vedecky-ctyrboj-logo-black-cs.png';
import logoEn from './assets/vedecky-ctyrboj-logo-black-en.png';
import Box from '@material-ui/core/Box';
import I18n from "./components/i18n/I18n";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  logo: {
    height: 100,
    padding: "10px"
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
}));


const Header = () => {
  const { currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const { t, i18n } = useTranslation()

  console.log("t.language ", t.language );

  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h5" color="inherit" noWrap className={classes.toolbarTitle}>
          <Link to="/">
            <img className={classes.logo} src={(i18n.language === "cs-CZ" || i18n.language === "sk-SK") ? logoCs : logoEn}
                 height="100" alt="Vědecký čtyřboj" />
          </Link>
        </Typography>
          {currentUser !== null ?
              <Box
                  display="flex"
                  flexDirection="row">
                  <TeamBar />
                  <I18n />
                  <Box>
                      <Button
                          color="primary"
                          variant="outlined"
                          className={classes.link}
                          onClick={() => firebase.auth().signOut()}>
                          {t("header.logout")}
                      </Button>
                  </Box>
              </Box>
              : <I18n />
          }

      </Toolbar>
    </AppBar>
  )
}

export default Header;