export default {
    translations: {
        header: {
            logout: 'Odhlášení',
            remainingTime: 'Zbývající čas',
            teamBar: {
                currentRanking: 'Průběžné pořadí',
                countPoints: 'Počet bodů',
                level: 'Úroveň',
            }
        },
        login: {
            title: 'Přihlášení',
            email: {
                label: 'Registrační emailová adresa týmu',
            },
            password: {
                label: 'Heslo',
            },
            login: {
                label: 'Přihlásit'
            },
            resetPassword: {
                label: 'Resetovat heslo'
            }
        },
        resetPassword: {
            title: 'Reset hesla',
            helpText: 'Zadejte prosím registrační emailovou adresu týmu, za pár okamžiků obdržíte email, ve kterém bude odkaz na kterém si nové' +
                ' heslo nastavíte. Po úspěšné změně se můžete vrátit na přihlášení.',
            email: {
                label: 'Registrační emailová adresa týmu',
            },
            resetPassword: {
                label: 'Resetovat heslo'
            },
            error: 'Chyba, opakujte akci a případně se obraťte prosím na organizátory.',
            errorEmail: 'Zadaný email není platný, překontrolujete překlepy a pokud si myslíte je zadaný správně obraťte se prosím na organizátory.'
        },
        app: {
            startTime: {
                title: 'Na soutěž je ještě brzo!',
                message: 'Otázky se na této stránce objeví až <strong>{{startDate}} v {{startTime}}</strong>. Otázky budou v daný čas automaticky' +
                    ' načteny. Pokud se automaticky neobjeví, zkuste prosím stránku obnovit.'
            },
            endTime: {
                title: 'Pozdě!',
                message: '<p>Časový limit pro vaše odpovědi vypršel <strong>{{startDate}} v {{startTime}}</strong>. V tuto chvíli jsou' +
                    ' vyhodnocovány vaše odpovědi na klíčové otázky a probíhá finální sčítání bodů.</p><p>Děkujeme vám za účast!</p>' +
                    '<p>Podělte se s námi o vaše dojmy ze soutěže a podněty pro zlepšení <a href="https://forms.gle/GaKwFqD7kCuRuGEx8" target="_blank">https://forms.gle/GaKwFqD7kCuRuGEx8</a>.</p>'
            },
            questionCard: {
                titleText: {
                    noAnswer: 'Otázka je zatím bez odpovědi',
                    correct: 'Otázka je správně odpovězena',
                    wrong: 'Otázka byla odpovězena chybně',
                    locked: 'Otázka je uzamčena do vyřešní alespoň jedné otázky z každé předmětu a alespoň poloviny otázek z dané úrovně',
                }
            },
            teamLeaderDialog: {
                title: 'Průběžné pořadí',
                table: {
                    header: {
                        order:'Pořadí',
                        teamName: 'Jméno týmu',
                        school: 'Škola',
                        countPoints: 'Počet bodů',
                        level: 'Úroveň',
                    }
                },
                close: {
                    label: 'Zavřít'
                }
            },
            questionDetail: {
                goBackButton: {
                    label: 'Seznam otázek'
                },
                level: {
                    label: 'Úroveň'
                },
                countPoints: {
                    label: 'Počet bodů'
                },
                audioError: 'Váš prohlížeč nepodporuje přehrávání zvuku. Prosím použijte jiný prohlížeč.',
                answer: {
                    label: 'Text odpovědi',
                    placeholder: 'Zde prosím zadejte text vaší odpovědi'
                },
                submit: {
                    label: 'Odeslat'
                },
                skip: {
                    label: 'Přeskočit',
                    tooltip: 'Po přeskočení nebudou přičteny žádné body a otázka bude uzavřena.'
                },
                answeredList: {
                    title: 'Přehled odeslaných odpovědí',
                    correctness: 'Správnost',
                    sendTime: 'Čas odeslání',
                    answer: 'Text odpovědi',
                },
                alerts: {
                    afterTheLimit: {
                        title: 'Pozdě!',
                        text: 'Časový limit pro vaše odpovědi vypršel.'
                    },
                    beforeNextAttempt: {
                        title: 'Ne tak rychle!',
                        text: 'Doba mezi jednotlivými pokusy o spravnou odpověď musí být delší než — <strong>1 minuta!</strong>'
                    },
                    wrongAnswer: {
                        title: 'Špatná odpověď!',
                        text: 'Bohužel, tato odpoveď byla vyhodnocena jako nesprávná — za jednu minutu můžete zkusit další pokus.'
                    },
                    success: {
                        title: 'Správná odpověď!',
                        text: 'Tato odpoveď byla vyhodnocena jako správná — gratulujeme a vzhůru k další otázce!'
                    },
                    skip: {
                        title: 'Přeskočeno',
                        text: 'Tato otázka byla přeskočena, k vyplnění odpovědi se již nelze vrátit.'
                    },
                    beforeNextAttemptTime_one: 'Další pokus na odpověď bude možný za {{count}} sekundu.',
                    beforeNextAttemptTime_few: 'Další pokus na odpověď bude možný za {{count}} sekundy.',
                    beforeNextAttemptTime_other: 'Další pokus na odpověď bude možný za {{count}} sekund.'
                }
            }
        }
    }
}