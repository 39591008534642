import React from 'react'
import { useTranslation } from 'react-i18next'
// useTranslation is a hook
// that returns a translation function (t) and the i18n instance

import {CzFlag, GbFlag, SkFlag} from '../../assets'
import Flag from './Flag'

const I18n = () => {
    const { i18n } = useTranslation()
    // i18n instance

    function handleChangeLanguage(language) {
        // changing the language when this function is called
        window.location.reload(false);
        i18n.changeLanguage(language);
    }

    const selectedLanguage = i18n.language
    return (
        <div className="flags-container">
            <Flag
                image={CzFlag}
                isSelected={selectedLanguage === 'cs-CZ'}
                onClick={() => handleChangeLanguage('cs-CZ')}
            />
            <Flag
                image={SkFlag}
                isSelected={selectedLanguage === 'sk-SK'}
                onClick={() => handleChangeLanguage('sk-SK')}
            />
            <Flag
                image={GbFlag}
                isSelected={selectedLanguage === 'en-GB'}
                onClick={() => handleChangeLanguage('en-GB')}
            />
        </div>
    )
}

export default I18n