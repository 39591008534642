export function calculateRank(snap) {
    let map = new Map();
    snap.docs.map(function (doc, i) {
        const team = doc.data();
        team.id = doc.id;
        if (map.has(team.sumOfPoints)) {
            map.get(team.sumOfPoints).push(team);
        } else {
            map.set(team.sumOfPoints, []);
            map.get(team.sumOfPoints).push(team);
        }
    });

    let rank = 1;
    for (const [, teams] of map.entries()) {
        if (teams.length === 1) {
            teams[0].rank = rank;
            rank++;
        } else {
            const startRankRange = rank;
            const endRankRange = rank + teams.length - 1;
            for (const t of teams) {
                t.rank = startRankRange + "-" + endRankRange;
            }
            rank = rank + teams.length;
        }
    }
    return Array.from(map.values()).flatMap(team => team);
}