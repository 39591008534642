import Box from "@material-ui/core/Box";
import ReactHtmlParser from "react-html-parser";
import {t} from "i18next";
import React from "react";
import TextField from "@material-ui/core/TextField";

export default function QuestionTextType(props) {

    return (
        <>
            <Box
                id="mathdiv"
                my={2}>
                {ReactHtmlParser(props.text)}
            </Box>
            <TextField
                id="outlined-basic"
                label={t("app.questionDetail.answer.label")}
                placeholder={t("app.questionDetail.answer.placeholder")}
                variant="outlined"
                value={props.answer}
                disabled={props.answered}
                onChange={(event) => props.onChange(event.target.value)}
                fullWidth
                required
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    autoComplete: 'none',
                }}
            />
        </>
    );
}
