import React, {useCallback, useState} from "react";
import {withRouter} from "react-router";
import app from '../Firebase';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {LockOpenOutlined} from "@material-ui/icons";
import {Copyright} from "./Login";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import {useTranslation} from "react-i18next";


const PasswordReset = ({history}) => {
    const { t } = useTranslation();
    const [badEmailErrorOpen, setBadEmailErrorOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

    const handlePasswordReset = useCallback(
        async event => {
            event.preventDefault();
            const {email} = event.target.elements;
            try {
                await app
                    .auth()
                    .sendPasswordResetEmail(email.value);
                history.push("/");
            } catch (error) {
                console.log(error);
                if (error.code === "auth/user-not-found") {
                    setBadEmailErrorOpen(true);
                } else {
                    setErrorOpen(true);
                }

            }
        },
        [history]
    );

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setBadEmailErrorOpen(false);
        setErrorOpen(false);
    };

    const emailFieldChange = useCallback(async event => {
        const email = event.target.value;
        if(email.length > 0) {
            setSubmitButtonDisabled(false);
        } else {
            setSubmitButtonDisabled(true);
        }
    }, []);



    const useStyles = makeStyles((theme) => ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

    const classes = useStyles();

    return (
        <Container
            component="main"
            maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar
                    className={classes.avatar}
                    style={{backgroundColor: '#00a9ce'}}>
                    <LockOpenOutlined style={{color: '#ffffff'}} />
                </Avatar>
                <Typography
                    component="h1"
                    variant="h5">
                    {t("resetPassword.title")}
                </Typography>
                <br />
                <p>
                    {t("resetPassword.helpText")}
                </p>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handlePasswordReset}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={emailFieldChange}
                        fullWidth
                        id="email"
                        label={t("resetPassword.email.label")}
                        name="email"
                        autoComplete="email"
                        onT
                        autoFocus
                    />
                    <Button
                        type="submit"
                        disabled={submitButtonDisabled}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                       {t("resetPassword.resetPassword.label")}
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <Snackbar
                open={badEmailErrorOpen}
                autoHideDuration={5000}
                onClose={handleCloseAlert}>
                <Alert severity="error">{t("resetPassword.errorEmail")}</Alert>
            </Snackbar>
            <Snackbar
                open={errorOpen}
                autoHideDuration={5000}
                onClose={handleCloseAlert}>
                <Alert severity="error">{t("resetPassword.error")}</Alert>
            </Snackbar>
        </Container>
    );
};

export default withRouter(PasswordReset);