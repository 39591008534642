import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import firebase from "../Firebase";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {calculateRank} from "../helpers"
import {useTranslation} from "react-i18next";

export default function TeamLeaderDialog(props) {
    const { t } = useTranslation();
    const {open, handleClose} = props;
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection("teams").orderBy("sumOfPoints", "desc")
            .onSnapshot(function (snap) {
                return setTeams(calculateRank(snap));
            })
        return () => unsubscribe()
    }, [])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="lg"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{t("app.teamLeaderDialog.title")}</DialogTitle>
            <DialogContent dividers={true}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t("app.teamLeaderDialog.table.header.order")}</TableCell>
                                <TableCell align="left">{t("app.teamLeaderDialog.table.header.teamName")}</TableCell>
                                <TableCell align="left">{t("app.teamLeaderDialog.table.header.school")}</TableCell>
                                <TableCell align="center">{t("app.teamLeaderDialog.table.header.countPoints")}</TableCell>
                                <TableCell align="center">{t("app.teamLeaderDialog.table.header.level")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teams && teams.map((team) => (
                                <TableRow key={team.id}>
                                    <TableCell align="left">{team.rank}</TableCell>
                                    <TableCell align="left">{team.name}</TableCell>
                                    <TableCell align="left">{team.school}</TableCell>
                                    <TableCell align="center">{isNaN(team.sumOfPoints) ? team.sumOfPoints : team.sumOfPoints.toFixed(2)}</TableCell>
                                    <TableCell align="cener">{team.level}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("app.teamLeaderDialog.close.label")}</Button>
            </DialogActions>
        </Dialog>
    )

}