export default {
    translations: {
        header: {
            logout: 'Logout',
            remainingTime: 'Remaining Time',
            teamBar: {
                currentRanking: 'Current Ranking',
                countPoints: 'Point Count',
                level: 'Level',
            },
        },
        login: {
            title: 'Login',
            email: {
                label: 'Team Registration Email Address',
            },
            password: {
                label: 'Password',
            },
            login: {
                label: 'Login',
            },
            resetPassword: {
                label: 'Reset Password',
            },
        },
        resetPassword: {
            title: 'Reset Password',
            helpText:
                'Please enter your team\'s registration email address. In a few moments, you will receive an email with a link to set a new' +
                ' password. After successful change, you can return to login.',
            email: {
                label: 'Team Registration Email Address',
            },
            resetPassword: {
                label: 'Reset Password',
            },
            error: 'Error, please retry the action and contact organizers if needed.',
            errorEmail: 'The entered email is not valid, check for typos and if you believe it\'s correct, please contact the organizers.',
        },
        app: {
            startTime: {
                title: "It's still early for the competition!",
                message:
                    'Questions will appear on this page at <strong>{{startDate}} at {{startTime}}</strong>. Questions will be automatically loaded at that time. If they don\'t appear automatically, please try refreshing the page.',
            },
            endTime: {
                title: 'Too Late!',
                message:
                    '<p>The time limit for your answers has expired at <strong>{{startDate}} at {{startTime}}</strong>. At this moment, your answers to key questions are being evaluated, and final point counting is taking place.</p><p>Thank you for your participation!</p><p>Share your thoughts on the competition and suggestions for improvement with us <a href="https://forms.gle/GaKwFqD7kCuRuGEx8" target="_blank">here</a>.</p>',
            },
            questionCard: {
                titleText: {
                    noAnswer: 'The question has no answer yet',
                    correct: 'The question has been answered correctly',
                    wrong: 'The question has been answered incorrectly',
                    locked: 'The question is locked until you solve at least one question from each subject and at least half of the questions from that level',
                },
            },
            teamLeaderDialog: {
                title: 'Current Ranking',
                table: {
                    header: {
                        order: 'Order',
                        teamName: 'Team Name',
                        school: 'School',
                        countPoints: 'Point Count',
                        level: 'Level',
                    },
                },
                close: {
                    label: 'Close',
                },
            },
            questionDetail: {
                goBackButton: {
                    label: 'List of Questions',
                },
                level: {
                    label: 'Level',
                },
                countPoints: {
                    label: 'Point Count',
                },
                audioError: 'Your browser does not support audio playback. Please use a different browser.',
                answer: {
                    label: 'Answer Text',
                    placeholder: 'Please enter your answer text here',
                },
                submit: {
                    label: 'Submit',
                },
                skip: {
                    label: 'Skip',
                    tooltip: 'After skipping, no points will be awarded and the question will be closed.'
                },
                answeredList: {
                    title: 'Overview of Submitted Answers',
                    correctness: 'Correctness',
                    sendTime: 'Submission Time',
                    answer: 'Answer Text',
                },
                alerts: {
                    afterTheLimit: {
                        title: 'Too Late!',
                        text: 'The time limit for your answers has expired.',
                    },
                    beforeNextAttempt: {
                        title: 'Not So Fast!',
                        text: 'The time between individual attempts to provide a correct answer must be longer than <strong>1 minute!</strong>',
                    },
                    wrongAnswer: {
                        title: 'Wrong Answer!',
                        text: 'Unfortunately, this answer has been evaluated as incorrect. You can try another attempt in one minute.',
                    },
                    success: {
                        title: 'Correct Answer!',
                        text: 'This answer has been evaluated as correct. Congratulations, and onwards to the next question!',
                    },
                    skip: {
                        title: 'Skipped',
                        text: 'This question has been skipped, you cannot go back to fill in the answer.'
                    },
                    beforeNextAttemptTime_one: 'Another reply attempt will be possible in {{count}} second.',
                    beforeNextAttemptTime_other: 'Another reply attempt will be possible in {{count}} seconds.'
                },
            },
        },
    },
};