import React, {useCallback, useContext, useEffect, useState} from "react";
import { withRouter, Redirect } from "react-router";
import app from '../Firebase';
import { AuthContext } from "../Auth.js";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GitInfo from "react-git-info/macro";
import {Link as RouterLink} from "react-router-dom";
import {AlertTitle} from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "../Firebase";
import {useTranslation} from "react-i18next";

export function Copyright() {
  const gitInfo = GitInfo();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'© '}
      <Link color="inherit" href="https://vedeckyctyrboj.cz/">
        vedeckyctyrboj.cz
      </Link>{' '}
      {new Date().getFullYear()}
      <p>{gitInfo.commit.shortHash}&nbsp;{gitInfo.commit.date}</p>
    </Typography>
  );
}



const Login = ({ history }) => {

  const [message, setMessage] = useState(Object);
  const { t } = useTranslation();

  useEffect(() => {
    const ref = firebase.firestore().collection('config/').doc("message");
    ref.onSnapshot(snap => setMessage(snap.data()));
  }, []);


  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser, admin } = useContext(AuthContext);

    const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

    const classes = useStyles();


  if (currentUser) {
    if(admin) {
      return <Redirect push to={`/admin`} />;
    } else {
      return <Redirect push to={`/team/${currentUser.uid}`} />;
    }
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  return (
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
        <Avatar className={classes.avatar} style={{ backgroundColor: '#00C42C' }}>
          <LockOutlinedIcon style={{ color: '#fff' }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("login.title")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("login.email.label")}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("login.password.label")}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("login.login.label")}
          </Button>
          <Button
              fullWidth
              variant="contained"
              to={`/password`}
              component={RouterLink}
          >
            {t("login.resetPassword.label")}
          </Button>
        </form>
      </div>
      <div className={classes.paper}>
        {message?.visible &&
            <Alert severity={message.severity}>
              {message.heading &&
                  <AlertTitle>{message.heading}</AlertTitle>
              }
              {message.text}
            </Alert>
        }

      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default withRouter(Login);