export default {
    translations: {
        header: {
            logout: 'Odhlásiť sa',
            remainingTime: 'Zostávajúci čas',
            teamBar: {
                currentRanking: 'Aktuálne poradie',
                countPoints: 'Počet bodov',
                level: 'Úroveň',
            },
        },
        login: {
            title: 'Prihlásenie',
            email: {
                label: 'Registračná e-mailová adresa tímu',
            },
            password: {
                label: 'Heslo',
            },
            login: {
                label: 'Prihlásiť sa',
            },
            resetPassword: {
                label: 'Obnoviť heslo',
            },
        },
        resetPassword: {
            title: 'Obnoviť heslo',
            helpText:
                'Prosím, zadajte registračnú e-mailovú adresu vášho tímu. O niekoľko okamihov neskôr obdržíte e-mail s odkazom na nastavenie nového hesla. Po úspešnej zmene sa môžete vrátiť na prihlásenie.',
            email: {
                label: 'Registračná e-mailová adresa tímu',
            },
            resetPassword: {
                label: 'Obnoviť heslo',
            },
            error: 'Chyba, opakujte akciu a prípadne sa obráťte na organizátorov.',
            errorEmail: 'Zadaný e-mail nie je platný, skontrolujte prípadné chyby a ak si myslíte, že je správne, obráťte sa na organizátorov.',
        },
        app: {
            startTime: {
                title: 'Na súťaž je ešte skoro!',
                message:
                    'Otázky sa na tejto stránke objavia až <strong>{{startDate}} o {{startTime}}</strong>. Otázky budú v daný čas automaticky načítané. Ak sa automaticky neobjavia, skúste stránku aktualizovať.',
            },
            endTime: {
                title: 'Neskoro!',
                message:
                    '<p>Časový limit pre vaše odpovede vypršal <strong>{{startDate}} o {{startTime}}</strong>. V tejto chvíli sú hodnotené vaše odpovede na kľúčové otázky a prebieha finálne sčítavanie bodov.</p><p>Ďakujeme vám za účasť!</p><p>Podeľte sa s nami o vaše dojmy zo súťaže a podnety pre zlepšenie <a href="https://forms.gle/GaKwFqD7kCuRuGEx8" target="_blank">https://forms.gle/GaKwFqD7kCuRuGEx8</a>.</p>',
            },
            questionCard: {
                titleText: {
                    noAnswer: 'Otázka zatiaľ nemá odpoveď',
                    correct: 'Otázka je správne zodpovedaná',
                    wrong: 'Otázka bola zodpovedaná nesprávne',
                    locked: 'Otázka je uzamknutá do vyriešenia aspoň jednej otázky z každej predmetu a aspoň polovice otázok z danej úrovne',
                },
            },
            teamLeaderDialog: {
                title: 'Aktuálne poradie',
                table: {
                    header: {
                        order: 'Poradie',
                        teamName: 'Meno tímu',
                        school: 'Škola',
                        countPoints: 'Počet bodov',
                        level: 'Úroveň',
                    },
                },
                close: {
                    label: 'Zavrieť',
                },
            },
            questionDetail: {
                goBackButton: {
                    label: 'Zoznam otázok',
                },
                level: {
                    label: 'Úroveň',
                },
                countPoints: {
                    label: 'Počet bodov',
                },
                audioError: 'Váš prehliadač nepodporuje prehrávanie zvuku. Prosím, použite iný prehliadač.',
                answer: {
                    label: 'Text odpovede',
                    placeholder: 'Sem prosím zadajte text vašej odpovede',
                },
                submit: {
                    label: 'Odoslať',
                },
                skip: {
                    label: 'Preskočiť',
                    tooltip: 'Po preskočení nebudú pripočítané žiadne body a otázka bude uzavretá.'
                },
                answeredList: {
                    title: 'Prehľad odoslaných odpovedí',
                    correctness: 'Správnosť',
                    sendTime: 'Čas odoslania',
                    answer: 'Text odpovede',
                },
                alerts: {
                    afterTheLimit: {
                        title: 'Neskoro!',
                        text: 'Časový limit pre vaše odpovede vypršal.',
                    },
                    beforeNextAttempt: {
                        title: 'Nie tak rýchlo!',
                        text: 'Čas medzi jednotlivými pokusmi o správnu odpoveď musí byť dlhší ako <strong>1 minúta!</strong>',
                    },
                    wrongAnswer: {
                        title: 'Zlá odpoveď!',
                        text: 'Ľutujeme, táto odpoveď bola vyhodnotená ako nesprávna. Za jednu minútu môžete skúsiť ďalší pokus.',
                    },
                    success: {
                        title: 'Správna odpoveď!',
                        text: 'Táto odpoveď bola vyhodnotená ako správna. Gratulujeme a pokračujte k ďalšej otázke!',
                    },
                    skip: {
                        title: 'Preskočené',
                        text: 'Táto otázka bola preskočená, k vyplneniu odpovede sa už nemožno vrátiť.'
                    },
                    beforeNextAttemptTime_one: 'Ďalší pokus na odpoveď bude možný za {{count}} sekundu.',
                    beforeNextAttemptTime_few: 'Ďalší pokus na odpoveď bude možný za {{count}} sekundu.',
                    beforeNextAttemptTime_other: 'Ďalší pokus na odpoveď bude možný za {{count}} sekúnd.'
                },
            },
        },
    },
};