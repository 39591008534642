import React from "react";
import firebase from './Firebase';
import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Countdown from "react-countdown-now";
import TeamLeaderDialog from "./components/TeamLeaderDialog";
import { withTranslation } from 'react-i18next';


class TeamBar extends React.Component {
  constructor(props) {
    super(props);
    this.refTeam = firebase.firestore().collection('teams').doc(firebase.auth().currentUser.uid);
    this.teamUnsubscribe = null;
    this.state = {
      team: undefined,
      startTime: null,
      endTime: null,
      startUploadTime: null,
      endUploadTime: null,
      teamLeaderOpen: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  onTeamCollectionUpdate = (documentSnapshpot) => {
    this.setState({
      team: documentSnapshpot.data(),
    })
  }

  componentDidMount() {
    this.teamUnsubscribe = this.refTeam.onSnapshot(this.onTeamCollectionUpdate);
     firebase.firestore().collection("config").doc("openTime").get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            startTime: new Date(doc.data().start.seconds * 1000 + doc.data().start.nanoseconds / 1000000),
            endTime: new Date(doc.data().end.seconds * 1000 + doc.data().end.nanoseconds / 1000000),
          });      
        } 
      }).catch((error) => {
        console.log("Error getting document:", error);
      });

    firebase.firestore().collection("config").doc("uploadOpenTime").get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({
              startUploadTime: new Date(doc.data().start.seconds * 1000 + doc.data().start.nanoseconds / 1000000),
              endUploadTime: new Date(doc.data().end.seconds * 1000 + doc.data().end.nanoseconds / 1000000),
            });
          }
        }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  handleClose = () => {
    this.setState({teamLeaderOpen: false});
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Tooltip title={firebase.auth().currentUser.uid} interactive>
          <Typography variant="h5" style={{
            textAlign: "right",
            paddingRight: "8px",
          }}>
            {this.state.team !== undefined ? `${this.state.team.number} - ${this.state.team.name} (${this.state.team.school})` : ''}
          </Typography>
        </Tooltip>
        {this.state.team !== undefined &&
          <Box display="flex" flexDirection="row-reverse" >
            {this.state.startTime != null && new Date() > this.state.startTime &&
            this.state.endTime != null && new Date() < this.state.endTime &&
            <Box p={1}>
              <Chip
                  color="secondary"
                  label={t("header.teamBar.currentRanking")}
                  onClick={() => this.setState({teamLeaderOpen: true})} />
            </Box>
            }
            <Box p={1}>
              <Chip avatar={<Avatar>{isNaN(this.state.team.sumOfPoints) ? this.state.team.sumOfPoints : this.state.team.sumOfPoints.toFixed(2)}</Avatar>} label={t("header.teamBar.countPoints")} />
            </Box>
            <Box p={1}>
              <Chip avatar={<Avatar>{this.state.team.level}</Avatar>} label={t("header.teamBar.level")} />
            </Box>
            { this.state.startUploadTime != null && new Date() > this.state.startUploadTime &&
            this.state.endUploadTime != null && new Date() < this.state.endUploadTime &&
            <Box p={2}>
              {t("header.remainingTime")}: <Countdown date={this.state.endUploadTime}  zeroPadTime="2"  daysInHours={true} />
            </Box>
            }
            { this.state.startTime != null && new Date() > this.state.startTime &&
            this.state.endTime != null && new Date() < this.state.endTime &&

            <Box p={2}>
              {t("header.remainingTime")}: <Countdown date={this.state.endTime}  zeroPadTime="2"  daysInHours={true} />
            </Box>
            }
          </Box>
        }
        <TeamLeaderDialog open={this.state.teamLeaderOpen} handleClose={this.handleClose}/>
      </Container>
    )
  }
}

export default withTranslation()(TeamBar);