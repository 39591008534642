import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Link from '@material-ui/core/Link';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import {useTranslation} from "react-i18next";




const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345
    },
    media: {
        height: 140
    },
    locked: {
        backgroundColor: theme.palette.action.disabledBackground,
    },
    open: {

    },

    badAnswer: {
        backgroundColor: theme.palette.warning.light,
    },

    correctAnswer: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.text.light
    }
}));

export default function QuestionCard(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    let titleText = t("app.questionCard.titleText.noAnswer");
    if(props.answered) {
        titleText = t("app.questionCard.titleText.correct");
    } else if(props.lastAttempt) {
        titleText = t("app.questionCard.titleText.wrong");
    } else if(props.opened === false) {
        titleText = t("app.questionCard.titleText.locked");
    }

    if (props.opened === true) {
        return (
            <Tooltip title={titleText}>
                <Card className={` ${classes.root} ${props.answered ? classes.correctAnswer : 'unanswered'}  ${props.lastAttempt ? classes.badAnswer : ''} ${classes.open} `}>
                    <Link component={RouterLink} to={`/team/${props.teamId}/question/${props.id}`} color="inherit" underline="none" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {props.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Link>
                </Card>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={titleText}>
                <Card className={` ${classes.root} ${props.answered ? classes.answered : 'unanswered'}  ${classes.locked} `}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {props.name}
                        </Typography>
                    </CardContent>
                </Card>
            </Tooltip>
        );
    }
}
