import React, {Component} from 'react';
import firebase from '../Firebase';
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {AlertTitle} from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AuthContext} from "./../Auth";
import i18next, {t} from 'i18next';
import QuestionTextType from "./QuestionTextType";
import QuestionMultichoiceType from "./QuestionMultichoiceType";
import {SkipNext} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import ReactHtmlParser from "react-html-parser";


const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  correct: {
    color: theme.palette.success.dark
  },
  wrong: {
    color: theme.palette.error.dark
  },
  buttonRoot: {
    display: "flex",
    alignItems: "center"
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertFlat(props) {
  return <MuiAlert elevation={0} variant="outlined" {...props} />;
}

class Show extends Component {

  constructor(props) {
    super(props);
    this.refAnswers = firebase.firestore().collection('teams/' + this.props.match.params.teamId + '/questions').doc(this.props.match.params.id).collection('answers').orderBy('date', 'desc');
    this.unsubscribe = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.timer = null;
    this.countDown = this.countDown.bind(this);

    this.state = {
      question: {},
      answers: [],
      answered: false,
      key: '',
      newAnswerLoading: false,
      emptyAnswer: true,
      timeLockAnswer: false,
      openLockAnswer: null,
      openLockAnswerSeconds: 0,
      textNewAnswer: [],
      alert: 0,
      possiblePoints: 0,
      teamId: this.props.match.params.teamId,
      adminMode: false,
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const answers = [];
    querySnapshot.forEach((answer) => {
      if (answer.exists) {
        const { text, correct, date } = answer.data();
        answers.push({
          key: answer.id, answers, text, correct, date
        });

        if(answers.length === 1 && correct === false) {
          // poslední odpověď
          if(date !== undefined) {
            const lastAttemptDate = date.toDate();
            lastAttemptDate.setMinutes(lastAttemptDate.getMinutes() + 1);
            const now = new Date();
            if(lastAttemptDate > now) {
              const timeout = lastAttemptDate.getTime() - now.getTime();
              this.setState({timeLockAnswer: true, openLockAnswer: lastAttemptDate, openLockAnswerSeconds:  Math.floor(timeout / 1000) });
              setTimeout(() => {
                this.setState({timeLockAnswer: false});
              }, timeout );
              this.timer = setInterval(this.countDown, 1000)
            } else {
              this.setState({timeLockAnswer: false, openLockAnswer: lastAttemptDate });
            }
          }
        }
        this.setState({
          answers: answers,
          isLoading: false
        });
      } else {
        console.log("No such document!");
      }
    });

    if(querySnapshot.size === 0) {
      this.setState({timeLockAnswer: false });
    }
  }

  countDown() {
    let sec = this.state.openLockAnswerSeconds - 1;
    this.setState({openLockAnswerSeconds: sec})

    if (sec <= 0) {
      clearInterval(this.timer);
    }
  }
  componentDidMount() {
    this.unsubscribe = this.refAnswers.onSnapshot(this.onCollectionUpdate);

    const ref = firebase.firestore().collection('teams/' + this.props.match.params.teamId + '/questions').doc(this.props.match.params.id);
    ref.get().then((doc) => {
      if (doc.exists) {


        this.setState({
          question: doc.data(),
          possiblePoints: doc.data().point,
          textNewAnswer: Array(doc.data().text[i18next.language].length).fill(''),
          answered: doc.data().answered,
          key: doc.id,
          isLoading: false
        });
        window["rerenderLatex"]();
      } else {
        console.log("No such document!");
      }
    });
    this.setState({ adminMode: this.context.admin});
  }

  handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ alert: 0 });
  };


  newAnswer() {
    this.setState({ newAnswerLoading: true });
    var addMessage = firebase.functions().httpsCallable('addNewAnswer');
    addMessage({
      text: this.state.textNewAnswer.map((answer) =>   typeof  answer === "string" ? answer.trim() : answer ),
      questionsId: this.props.match.params.id,
      teamId: this.props.match.params.teamId,
      language: i18next.language
    })
      .then((result) => {
        var isCorrect = result.data.isCorrect;
        var message = result.data.message;
        const possiblePoints = result.data.possiblePoints;
        if (isCorrect) {
          this.setState({ answered: true, alert: 2 });
        } else {
          if (message == 'time') {
            this.setState({ alert: 1 });
          } else if (message == 'timeSlot') {
            this.setState({ alert: 4 });
          } else {
            this.setState({ emptyAnswer: true, textNewAnswer: Array(this.state.textNewAnswer.length).fill(''), alert: 3, possiblePoints: possiblePoints});
          }
        }
        this.setState({ newAnswerLoading: false });
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
        console.log(error.details);
        this.setState({ newAnswerLoading: false });
      })
  }

  ackAnswer(answerId) {
    var ackAnswer = firebase.functions().httpsCallable('ackAnswer');
    ackAnswer({
      answerId: answerId,
      questionsId: this.props.match.params.id,
      teamId: this.props.match.params.teamId
    })
  }

  skipQuestion() {
    this.setState({ newAnswerLoading: true });
    var skipQuestion = firebase.functions().httpsCallable('skipQuestion');
    skipQuestion({
      questionsId: this.props.match.params.id,
      teamId: this.props.match.params.teamId,
    }).then((result) => {
      if (result.data.isCorrect) {
        this.setState({answered: true, alert: 5});
      }
      this.setState({ newAnswerLoading: false });
    }).catch((error) => {
      console.log(error.code);
      console.log(error.message);
      console.log(error.details);
      this.setState({ newAnswerLoading: false });
    })
  }

  handleChange(value, index) {
    const updatedArray = this.state.textNewAnswer.map((answer, i) => {
      if(i === index) {
        return value;
      } else {
        return answer;
      }
    })
    this.setState({ emptyAnswer: !updatedArray.every((answer) => answer.length > 0) });
    this.setState({ textNewAnswer: updatedArray });
  }
  handleSubmit(event) {
    this.newAnswer();
    event.preventDefault();
  }

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <CssBaseline />
        <Box my={2}>
          <Button
            variant="outlined"
            onClick={() => this.props.history.goBack()}
            startIcon={<ArrowBackIcon />}>{t("app.questionDetail.goBackButton.label")}</Button>
          {this.state.question.name !== undefined && (
          <Paper elevation={1} >
            <Box my={2} padding={2} >
              <Box mb={2}>
                <Typography variant="h3">
                  {this.state.question.name[i18next.language]}
                </Typography>
                <Box>
                  <Chip className={classes.chip} avatar={<Avatar>{this.state.question.level}</Avatar>} label={t("app.questionDetail.level.label")} />
                  <Chip className={classes.chip} avatar={<Avatar>{this.state.possiblePoints}</Avatar>} label={t("app.questionDetail.countPoints.label")} />
                </Box>
              </Box>
              <div class="panel-body">
                <form onSubmit={this.handleSubmit} autoComplete="off">
                  {this.state.question.text[i18next.language].map((question, questionIndex) => {
                    const type = question.type;
                    let questionComponent;
                    if(type === "text") {
                      questionComponent =
                          <QuestionTextType
                              text={question.value}
                              answer={this.state.textNewAnswer[questionIndex]}
                              answered={this.state.answered}
                              onChange={(text) => this.handleChange(text, questionIndex)}
                          />
                    } else if (type === "multichoice") {
                      questionComponent =
                          <QuestionMultichoiceType
                              text={question.title}
                              choices={question.choices}
                              answer={this.state.textNewAnswer[questionIndex]}
                              answered={this.state.answered}
                              onChange={(choices) => this.handleChange(choices, questionIndex)}/>
                    }
                    return (
                        <span key={questionIndex}>
                          {questionComponent}
                          {question.answerHint !== undefined &&
                              <Box fontStyle="italic" my={2}>
                                {question.answerHint}
                              </Box>
                          }
                          {question.attachment !== undefined &&
                           <>
                             {question.attachment.type === 'image' &&
                                 <Box my={2}>
                                   <img style={{maxWidth: "100%"}} src={question.attachment.src} />
                                 </Box>
                             }
                             {question.attachment.type === 'mp3' &&
                                 <Box my={2}>
                                   <audio controls>
                                     <source src={question.attachment.src} type="audio/mpeg" />
                                     {t("app.questionDetail.audioError")}
                                   </audio>
                                 </Box>
                             }
                           </>
                          }
                        </span>
                    );
                  })}
                  <Box my={2} className={classes.buttonRoot}>
                    <div className={classes.buttonWrapper}>
                      <Button type="submit" variant="contained" size="large" color="primary"  disabled={this.state.answered || this.state.newAnswerLoading || this.state.emptyAnswer || this.state.timeLockAnswer} startIcon={<SendIcon />}  >
                        {t("app.questionDetail.submit.label")}
                    </Button>
                        {this.state.newAnswerLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    {this.state.question.skippable &&
                    <div className={classes.buttonWrapper}>
                      <Tooltip title={t("app.questionDetail.skip.tooltip")}>
                        <Button onClick={() => this.skipQuestion()} variant="outlined" size="large" color="default"  disabled={this.state.answered || this.state.newAnswerLoading} startIcon={<SkipNext />}  >
                          {t("app.questionDetail.skip.label")}
                        </Button>
                      </Tooltip>
                      {this.state.newAnswerLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    }
                  </Box>
                  {this.state.timeLockAnswer &&
                      <Box my={2}>
                        <AlertFlat severity="warning">
                          {t("app.questionDetail.alerts.beforeNextAttemptTime", {count: this.state.openLockAnswerSeconds})}
                        </AlertFlat>
                      </Box>
                  }
                  {this.state.answers.length > 0 &&
                    <Box my={2} mt={8}>
                      <Typography variant="h6">
                        {t("app.questionDetail.answeredList.title")}
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '2rem' }}>{t("app.questionDetail.answeredList.correctness")}</TableCell>
                              <TableCell style={{ width: '10rem' }}>{t("app.questionDetail.answeredList.sendTime")}</TableCell>
                              <TableCell>{t("app.questionDetail.answeredList.answer")}</TableCell>
                              {this.state.adminMode == true &&
                                <TableCell></TableCell>
                              }
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.answers.map(answer =>
                              <TableRow>
                                <TableCell align="center">
                                  {answer.correct == true ? <CheckCircleIcon className={classes.correct} /> : <CancelIcon className={classes.wrong} />
                                  }
                                </TableCell>
                                <TableCell>{new Intl.DateTimeFormat("en-GB", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric"
                                }).format(new Date(answer.date.seconds * 1000 + answer.date.nanoseconds / 1000000))}</TableCell>
                                <TableCell>
                                  <Box
                                      id="mathdiv"
                                      my={2}>
                                    {ReactHtmlParser(answer.text)}
                                  </Box>
                                  </TableCell>
                                {this.state.adminMode == true && answer.correct != true &&
                                <TableCell>
                                  <Button onClick={() => this.ackAnswer(answer.key)} variant="contained" color="secondary" disabled={this.state.answered || this.state.newAnswerLoading}>
                                    Uznat odpověď
                                  </Button>
                                </TableCell>
                              }
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  }
                </form>
              </div>
            </Box>
          </Paper>
              )}
        </Box>
        <Snackbar open={this.state.alert == 5} autoHideDuration={5000} onClose={this.handleCloseAlert}>
          <Alert severity="success">
            <AlertTitle>{t("app.questionDetail.alerts.skip.title")}</AlertTitle>
            {t("app.questionDetail.alerts.skip.text")}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.alert == 4} autoHideDuration={5000} onClose={this.handleCloseAlert}>
          <Alert severity="error">
            <AlertTitle>{t("app.questionDetail.alerts.afterTheLimit.title")}</AlertTitle>
            {t("app.questionDetail.alerts.afterTheLimit.text")}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.alert == 3} autoHideDuration={5000} onClose={this.handleCloseAlert}>
          <Alert severity="error">
            <AlertTitle>{t("app.questionDetail.alerts.wrongAnswer.title")}</AlertTitle>
            {t("app.questionDetail.alerts.wrongAnswer.text")}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.alert == 2} autoHideDuration={5000} onClose={this.handleCloseAlert}>
          <Alert severity="success">
            <AlertTitle>{t("app.questionDetail.alerts.success.title")}</AlertTitle>
            {t("app.questionDetail.alerts.success.text")}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.alert == 1} autoHideDuration={5000} onClose={this.handleCloseAlert}>
          <Alert severity="warning">
            <AlertTitle>{t("app.questionDetail.alerts.beforeNextAttempt.title")}</AlertTitle>
            <span dangerouslySetInnerHTML={{__html: t("app.questionDetail.alerts.beforeNextAttempt.text")}} />
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

Show.contextType = AuthContext;

export default withStyles(styles)(Show);