import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import firestore from 'firebase/firestore'

const settings = {timestampsInSnapshots: true};

const config = {
   apiKey: "AIzaSyCnP39hoP9ixVR1Rxr4TtB4vkW78X7NskM",
    authDomain: "vedecky-ctyrboj.firebaseapp.com",
    projectId: "vedecky-ctyrboj",
    storageBucket: "vedecky-ctyrboj.appspot.com",
    messagingSenderId: "759130805795",
    appId: "1:759130805795:web:b31c7a93a52468f6e6c396",
    measurementId: "G-VV05EG29WY"
};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

var functions = firebase.functions();

var storage = firebase.storage();

export const auth = firebase.auth();

 const db = firebase.firestore()
  if (window.location.hostname === "localhost") {
    
  //  auth.useEmulator("http://localhost:9099");
    db.useEmulator("localhost", 8080);
    firebase.functions().useEmulator("localhost", 5001);

  }


export default firebase;