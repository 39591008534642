
import React, { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


import app from "./Firebase";


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [admin, setAdmin] = useState(true);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user != null) {
        app.auth().currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            if (!!idTokenResult.claims.admin) {
             setAdmin(true);
            } else {
             setAdmin(false);
            }
            setPending(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setPending(false);
      }
    });



  }, []);

  if (pending) {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          display="flex"
          align="center"
          width={350} height={300}
        >
          <Box m="auto">
            <CircularProgress />
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        admin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};